<template>
  <v-card outlined style="border-radius: 16px">
    <v-card-title class="d-flex">
      <h2 class="mr-3">
        🥳
      </h2>
      Aniversariantes do mês
    </v-card-title>
    <v-card-text>
      <v-list dense>
        <v-list-item-group color="primary">
          <v-list-item v-for="(customer, i) in customers" :key="i">
            <v-list-item-icon>
              {{ formatDate(customer.birth_date) }}
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="customer.name"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { format, parseISO } from "date-fns";

export default {
  data: () => ({
    customers: [],
  }),

  mounted() {
    this.$http.index("dashboards/birthdays").then((response) => {
      this.customers = response.customers;
    });
  },

  methods: {
    formatDate(birth_date) {
      return format(parseISO(birth_date), "dd/MM");
    },
  },
};
</script>
<style></style>
