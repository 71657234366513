<template>
  <v-card outlined style="border-radius: 16px">
    <v-card-title class="d-flex justify-space-between align-baseline">
      <span>WhatsApp</span>
      <div>
        <v-chip v-if="status" small :color="statuses[status].color">
          {{ statuses[status].text }}
        </v-chip>
      </div>
    </v-card-title>

    <v-card-text v-if="status !== 'unavailable'">
      <template v-if="isDisconnected">
        <div class="d-flex justify-center" v-if="QRCode">
          <QRcodeView :value="QRCode" />
        </div>
      </template>
      <template v-if="!isDisconnected">
        <div class="d-flex justify-space-around">
          <v-btn rounded color="primary" @click="restart">
            <v-icon left> mdi-restart </v-icon>
            Reiniciar
          </v-btn>
          <div>
            <app-cancel-btn
              @click="disconnect"
              text="Desconectar"
              icon="mdi-cellphone-nfc-off"
            />
          </div>
        </div>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import QRcodeView from "@chenfengyuan/vue-qrcode";

export default {
  components: {
    QRcodeView,
  },

  data() {
    return {
      statuses: {
        open: {
          text: "Conectado",
          color: "success",
        },

        close: {
          text: "Desconectado",
          color: "error",
        },

        connecting: {
          text: "Conectando",
          color: "warning",
        },

        unavailable: {
          text: "Indisponível",
          color: "error",
        },
      },
    };
  },

  async mounted() {
    this.$store.dispatch("whatsapp/getStatus");
  },

  computed: {
    status() {
      return this.$store.getters["whatsapp/status"];
    },

    QRCode() {
      return this.$store.getters["whatsapp/QRCode"];
    },

    isDisconnected() {
      return this.$store.getters["whatsapp/isDisconnected"];
    },
  },

  methods: {
    disconnect() {
      this.$store.dispatch("whatsapp/disconnect");
    },
    restart() {
      this.$store.dispatch("whatsapp/restart");
    },
  },
};
</script>
 
<style>
</style>