<template>
  <div>
    <v-row>
      <v-col>
        <UserWelcomeent />
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col>
        <v-alert color="warning">
          <h3>Alteração no sistema de notificações de sessões!</h3>

          Conforme pedidos agora a validação do número de WhatsApp é automática.
          <br />
          Seu paciente poderá confirmar a sessão ou pedir remarcação digitando 1
          e 2 (de acordo com suas configurações).
          <br />
          <br />
          Equipe Psicoplanner.
        </v-alert>
      </v-col>
    </v-row> -->
    <!-- <v-row v-if="hasChatBot">
      <v-col>
        <v-alert color="primary" text>
          <h3>
            Novo sistema de notificação de sessões!
            <v-chip class="ml-2 mb-1 " small color="info">Beta</v-chip>
          </h3>
          <div class="my-2">
            Para fornecermos mais segurança e novos recursos, seus pacientes devem ser validados para integração com o WhatsApp.
            <br />
            Você possui um código de segurança que seu paciente deverá enviar para o numero de Whatsapp de notificações do Psicoplanner:
            {{ company.whatsapp_api_number }}.
            <br />
          </div>
          Seu código de segurança :
          <strong>{{ company.phone_wa_security_code }}</strong>
          <br />
          <small>
            Para mais informações
            <a @click="$router.push('/ajuda/validar-whatsapp')"><b>Clique aqui!</b></a>
          </small>
        </v-alert>
      </v-col>
    </v-row> -->
    <v-row>
      <v-col cols="12" md="6">
        <DashboardSessionStats />
        <FirstSteps v-if="showOnboarding" />
        <DashboardNextSessions />
      </v-col>
      <v-col>
        <template v-if="hasInstance && $acl.isOwner()">
          <WhatsAppInstanceCard />
          <br />
        </template>
        <div class="mb-4">
          <v-alert text icon="mdi-whatsapp" :color="'primary'">
            <small>Precisando de ajuda?</small>
            <br />
            <span>Estamos disponíveis no</span>
            <b>WhatsApp!</b>
            <br />
            <v-btn class="mt-2" outlined color="primary" @click="getHelp()">
              Falar com especialista!
            </v-btn>
          </v-alert>
        </div>
        <DashboardBirthdays />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DashboardSessionStats from "../components/dashboard/sections/DashboardSessionStats.vue";
import UserWelcomeent from "../components/dashboard/sections/UserWelcomeent.vue";
import WhatsAppInstanceCard from "@/components/dashboard/sections/WhatsAppInstanceCard.vue";
import FirstSteps from "../components/dashboard/sections/FirstSteps.vue";
import DashboardBirthdays from "@/components/dashboard/sections/DashboardBirthdays.vue";
import DashboardNextSessions from "@/components/dashboard/sections/DashboardNextSessions.vue";
import { differenceInDays, parseISO, formatISO } from "date-fns";

export default {
  components: {
    DashboardNextSessions,
    DashboardSessionStats,
    DashboardBirthdays,
    FirstSteps,
    UserWelcomeent,
    WhatsAppInstanceCard,
  },
  data() {
    return {};
  },

  computed: {
    company() {
      return this.$store.state.auth.company;
    },

    hasChatBot() {
      return this.company.has_chatbot == 1;
    },

    hasInstance() {
      return this.$store.state.auth.company.whatsapp_instance !== null;
    },

    showOnboarding() {
      return (
        differenceInDays(
          parseISO(formatISO(new Date())),
          parseISO(this.company.created_at)
        ) < 7
      );
    },
  },

  methods: {
    getHelp() {
      let link = `https://api.whatsapp.com/send?phone=+${process.env.VUE_APP_SUPPORT_WHATSAPP}`;

      let msg = "&text=Olá, Preciso de ajuda!";

      window.open(link + msg);
    },
  },
};
</script>
