<template>
  <v-card outlined style="border-radius: 16px">
    <v-card-title>
      <app-icon color="primary" class="mr-3">event</app-icon>
      <span>Próximos Eventos</span>
      <v-btn
        @click="handleSearchProfessionals()"
        class="ml-auto"
        text
        icon
        color="primary"
        v-if="showSearchProfessional"
      >
        <app-icon>search</app-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-tabs id="tabSessions" class="mb-4" v-model="tab">
        <v-tab @click="getSessions(today)">Hoje</v-tab>
        <v-tab @click="getSessions(tomorrow)"> Amanhã </v-tab>
        <v-tab @click="getSessions(week)"> Esta Semana </v-tab>
      </v-tabs>
      <template v-if="!booted">
        <v-skeleton-loader type="list-item-three-line" />
        <v-skeleton-loader type="list-item-three-line" />
        <v-skeleton-loader type="list-item-three-line" />
        <v-skeleton-loader type="list-item-three-line" />
      </template>
      <app-pagination v-if="booted" @click="select($event)" :data="sessions">
        <template v-for="(session, index) in sessions.data">
          <SessionCard
            class="mt-2"
            @click.stop="handleShowEvent(session.id)"
            :session="session"
            :showDate="tab > 0"
            :key="index"
          />
        </template>
      </app-pagination>
    </v-card-text>
    <SessionDialog ref="SessionDialog" @store="select()" />
    <SelectProfessional
      class="d-none"
      ref="SelectProfessional"
      :multiple="true"
      @input="setProfessionals($event)"
    />
  </v-card>
</template>



<script>
import SessionNotificationStatus from "../../session/sections/SessionNotificationStatus.vue";
import { format, addDays, lastDayOfWeek } from "date-fns";
import SessionDialog from "@/components/session/sections/SessionDialog.vue";
import ButtonSendVideoLink from "@/components/session/sections/ButtonSendVideoLink.vue";
import CustomerAvatar from "@/components/customers/sections/CustomerAvatar.vue";
import SelectProfessional from "@/components/app/forms/SelectProfessional.vue";
import SessionCard from "@/components/session/sections/SessionCard.vue";
// import SessionStatusByCustomer from "@/components/session/sections/SessionStatusByCustomer.vue";

export default {
  components: {
    SessionDialog,
    CustomerAvatar,
    ButtonSendVideoLink,
    // SessionStatusByCustomer,
    SessionNotificationStatus,
    SelectProfessional,
    SessionCard,
  },
  data() {
    return {
      tab: 0,
      sessions: [],
      booted: false,
      loading: false,
      searchParams: {
        page: 1,
        start: null,
        end: null,
      },

      tabTexts: {
        0: {
          title: "Muito quieto hoje...",
          subtitle: "Que tal agendar algumas sessões?",
        },
        1: {
          title: "Agenda livre amanhã...",
          subtitle: "Marque uma sessão e o Psicoplanner envia o lembrete!",
        },
        2: {
          title: "Nada na agenda pra essa semana",
          subtitle: "Bora planejar tudo!",
        },
      },
    };
  },
  mounted() {
    if (this.$acl.isProfessional()) {
      this.searchParams.professionals = [this.user.id];
    }

    this.getSessions(this.today);
  },

  computed: {
    today() {
      return {
        start: format(new Date(), "yyyy-MM-dd"),
        end: format(new Date(), "yyyy-MM-dd"),
      };
    },
    tomorrow() {
      return {
        start: format(addDays(new Date(), 1), "yyyy-MM-dd"),
        end: format(addDays(new Date(), 1), "yyyy-MM-dd"),
      };
    },
    week() {
      return {
        start: format(new Date(), "yyyy-MM-dd"),
        end: format(lastDayOfWeek(new Date()), "yyyy-MM-dd"),
      };
    },

    user() {
      return this.$store.state.auth.user;
    },
    showSearchProfessional() {
      return (
        (this.isClinic && this.$acl.can("can_manage_all_calendar")) ||
        this.$acl.isAttendant()
      );
    },
  },

  methods: {
    getSessions(dateBetween) {
      this.searchParams.start = dateBetween.start;
      this.searchParams.end = dateBetween.end;
      this.select();
    },

    select(page) {
      this.searchParams.page = page;
      this.loading = true;
      this.$http
        .index("dashboards/next-sessions", this.searchParams)
        .then((response) => {
          this.sessions = response.sessions;
          this.booted = true;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    handleShowEvent(session_id) {
      this.$refs.SessionDialog.open(session_id);
    },

    videoCallLink(session) {
      return process.env.BASE_URL + "/videochamada/" + session.uuid;
    },

    notShowed(session) {
      return session.status != "shown";
    },

    handleNewSession() {
      this.$router.push("/agenda");
    },
    handleSearchProfessionals() {
      this.$refs.SelectProfessional.open();
    },
    setProfessionals(professionals) {
      this.searchParams.professionals = professionals.map(
        (professional) => professional.id
      );
      this.select();
    },
  },
};
</script>

<style lang="scss">
#tabSessions {
  .v-slide-group__next--disabled,
  .v-slide-group__prev--disabled {
    display: none;
  }
  .v-slide-group__next,
  .v-slide-group__prev {
    display: none;
  }
}
</style>
