<template>
  <div>
    <h1 class="d-inline">{{ compliment }}</h1>
    <h1 class="d-inline">{{ userFirstName }}</h1>
  </div>
</template>

<script>
export default {
  computed: {
    userFirstName() {
      return this.$store.getters["auth/userFirstName"];
    },

    compliment() {
      var today = new Date();

      var hour = today.getHours();

      if (hour < 12) {
        return "Bom dia ";
      } else if (hour < 18) {
        return "Boa tarde ";
      } else {
        return "Boa noite ";
      }
    },
  },
};
</script>

<style>
</style>