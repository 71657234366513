<template>
  <v-card class="mb-4" outlined style="border-radius: 16px">
    <v-card-title> Primeiros passos...</v-card-title>
    <v-card-text>
      <span>
        São só dois passos simples para começar a ter mais tempo livre!
      </span>
      <v-list>
        <v-list-item
          class="py-3 ma-0 flex-column"
          @click="$router.push('/clientes')"
          style="min-height: 0px"
        >
          <div class="d-flex align-center mb-2">
            <div class="pr-2">
              <b
                :class="[
                  { 'text-decoration-line-through': hasCustomer },
                  'primary--text',
                ]"
              >
                1. Cadastre seu primeiro {{ customerAlias }}</b
              >
              <br />
              <span>
                O primeiro passo é ter seu {{ customerAlias }} cadastrado, tendo
                acesso ao seu prontuário, anamnese, documentos e mais!</span
              >
            </div>
            <div class="px-2">
              <app-icon v-if="hasCustomer" color="primary" large>done</app-icon>
              <app-icon v-else color="primary" large> arrow_forward </app-icon>
            </div>
          </div>
        </v-list-item>

        <v-list-item
          class="py-3 ma-0 flex-column"
          @click="$router.push('/agenda')"
          style="min-height: 0px"
        >
          <div class="d-flex align-center mb-2">
            <div class="pr-2">
              <b
                :class="[
                  { 'text-decoration-line-through': hasSession },
                  'primary--text',
                ]"
                >2. Cadastre sua primeira sessão</b
              >
              <br />
              <span
                >Aqui a mágica acontece! Com o agendamento feito iremos enviar
                os lembretes de whatsapp e você pode acompanhar o status e
                confirmações!</span
              >
            </div>
            <div class="px-2">
              <app-icon v-if="hasSession" color="primary" large>done</app-icon>
              <app-icon v-else color="primary" large> arrow_forward </app-icon>
            </div>
          </div>
        </v-list-item>
      </v-list>
      <FirstStepsVideo class="mb-4" />
    </v-card-text>
    <!--     <v-card-title
      ><app-icon color="primary" class="mr-3">school</app-icon> Primeiros
      passos...</v-card-title
    >
    <v-card-text>
      <span>
        São só dois passos simples para começar a ter mais tempo livre!
      </span>
      <v-list>
        <v-list-item
          class="py-3 ma-0 flex-column"
          @click="$router.push('/clientes')"
          style="min-height:0px"
        >
          <div class="d-flex align-center mb-2" >
            <div class="pr-2">
              <b
                :class="[
                  { 'text-decoration-line-through': hasCustomer },
                  'primary--text',
                ]"
                >1. Cadastre seu primeiro {{ customerAlias }}</b
              >
              <br />
              <span>
                O primeiro passo é ter seu {{ customerAlias }} cadastrado, tendo
                acesso ao seu prontuário, anamnese, documentos e mais!</span
              >
            </div>
            <div class="px-2">
              <app-icon v-if="hasCustomer" color="primary" large
                >done</app-icon
              >
              <app-icon v-else color="primary" large> arrow_forward </app-icon>
            </div>
          </div>
          <div v-if="!hasCustomer" style="width:100%">
            <v-btn  small color="primary" @click="$router.push('/clientes')"> Cadastrar {{ customerAlias }} </v-btn>
          </div>
        </v-list-item>

        <v-list-item class="py-3 ma-0 flex-column" @click="$router.push('/agenda')" style="min-height:0px">
          <div class="d-flex align-center mb-2">
            <div class="pr-2">
              <b
                :class="[
                  { 'text-decoration-line-through': hasSession },
                  'primary--text',
                ]"
                >2. Cadastre sua primeira sessão</b
              >
              <br />
              <span
                >Aqui a mágica acontece! Com o agendamento feito iremos enviar
                os lembretes de whatsapp e você pode acompanhar o status e
                confirmações!</span
              >
            </div>
            <div class="px-2">
              <app-icon v-if="hasSession" color="primary" large
                >done</app-icon
              >
              <app-icon v-else color="primary" large> arrow_forward </app-icon>
            </div>
          </div>
          <div v-if="!hasSession" style="width:100%">
            <v-btn  small color="primary"> Cadastrar Sessão </v-btn>
          </div>
        </v-list-item>
      </v-list>
    </v-card-text> -->
  </v-card>
</template>

<script>
import { customerAlias } from "@/utils/customerAlias";
import FirstStepsVideo from "@/components/app/sections/FirstStepsVideo.vue";

export default {
  components: {
    FirstStepsVideo,
  },
  computed: {
    customerAlias() {
      return customerAlias();
    },

    company() {
      return this.$store.state.auth.company;
    },

    hasCustomer() {
      return this.$store.state.onboarding.hasCustomer;
    },

    hasSession() {
      return this.$store.state.onboarding.hasSession;
    },
  },
};
</script>

<style>
</style>