<template>
  <v-hover v-slot="{ hover }">
    <v-alert
      style="cursor: pointer"
      border="left"
      colored-border
      v-on="$listeners"
      dense
      :elevation="hover ? 12 : 0"
      :class="{ 'on-hover': hover }"
      :color="session.color"
    >
      <div class="d-flex align-center">
        <template v-if="isSessionType">
          <b class="mr-md-auto">
            {{ customerName }}
            <template v-if="session.is_online_session">
              <v-icon color="primary"> mdi-video </v-icon>
            </template>
          </b>
          <small
            @click.stop="handleVideoCallSendLik(session)"
            type="button"
            v-if="$acl.isProfessional()"
            class="v-btn theme--light primary--text d-none d-md-flex"
          >
            <app-icon class="mr-2" color="primary" small>videocam</app-icon>
            Video Chamada
          </small>
        </template>
        <template v-else>
          <b class="mr-md-auto">
            {{ session.title }}
          </b>
        </template>
      </div>

      <div v-if="$acl.isClinic()" class="d-flex align-center">
        <app-icon class="mr-2" small>badge</app-icon>
        <small class="mr-auto">{{ session.professional.name }}</small>
      </div>
      <div class="d-flex align-center">
        <template v-if="showDate">
          <app-icon class="mr-2" small>event</app-icon>
          <small class="mr-4">
            {{ $format.dateBr(session.start_at_date) }}
          </small>
        </template>

        <app-icon class="mr-2" small>schedule</app-icon>
        <small>
          {{ $format.time(session.start_at_time) }} -
          {{ $format.time(session.end_at_time) }}
        </small>
      </div>
      <small v-if="isBooking">
        <v-icon small left> mdi-tooltip-account </v-icon>
        Agendado pelo App
      </small>
      <!-- <small class="text--error"> Agendado pelo App </small> -->
      <SessionNotificationStatus v-if="isSessionType" :session="session" />
      <small v-if="!isSessionType" class="text--disabled">
        (Compromisso pessoal)
      </small>
      <v-btn
        v-if="$acl.isProfessional()"
        @click.stop="handleVideoCallSendLik(session)"
        class="px-0 d-md-none"
        text
        color="primary"
      >
        <app-icon class="mr-2" color="primary" small>videocam</app-icon>
        Video Chamada
      </v-btn>
      <VideoCallLinkSendingDialog ref="VideoCallLinkSendingDialog" />
    </v-alert>
  </v-hover>
</template>

<script>
import SessionNotificationStatus from "@/components/session/sections/SessionNotificationStatus.vue";
import VideoCallLinkSendingDialog from "@/components/session/sections/VideoCallLinkSendingDialog.vue";

export default {
  props: {
    session: {},
    showDate: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    SessionNotificationStatus,
    VideoCallLinkSendingDialog,
  },

  computed: {
    isSessionType() {
      return this.session.session_type == "session";
    },

    isBooking() {
      return this.session.booking_id != null;
    },

    customerName() {
      return this.isBooking
        ? this.session.booking.name
        : this.session.customer.name;
    },
  },

  methods: {
    handleVideoCallSendLik(session) {
      this.$refs.VideoCallLinkSendingDialog.open(session);
    },
  },
};
</script>

<style lang="sass" scoped></style>
