<template>
  <div v-if="hideVideo" class="mb-6 container">
    <iframe
      class="responsive-iframe"
      src="https://www.youtube.com/embed/hNUFov6D2Vs"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    />
  </div>
</template>

<script>
import { differenceInDays, parseISO, formatISO } from "date-fns";

export default {
  computed: {
    company() {
      return this.$store.state.auth.company;
    },
    
    hideVideo() {
      return (
        differenceInDays(
          parseISO(formatISO(new Date())),
          parseISO(this.company.created_at)
        ) < 15
      );
    },
  },
};
</script>

<style   scoped>
.container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
</style>
